html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

#root,
.App {
  font-family: Arial, sans-serif;
  height: 100%;
  width: 100%;
}

/* Menu.css */
.menu {
  background: #373a47;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5em;
  padding: 1.5em;
}

.menu .active a{
  color: #d1242a;
}

.menu-item a {
  color: rgb(227, 227, 227);
  text-decoration: none;
}

.menu-item a:hover {
  color: #d1242a;
}

.social-media {
  display: flex;
  flex-direction: row;
  gap: 2em;
  justify-content: center;
  align-content: center;
}

.content-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(227, 227, 227);
  height: 100%;
  padding-top: 1.5em;
  gap: 2.5em;
}

.img_logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile {
  padding-top: 2em;
  display: flex;
  flex-direction: column;
}

.profile-photo-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

@media only screen and (max-width: 1170px) {
  .profile-photo-title {
    flex-direction: column;
  }
  .profile-title {
    text-align: center;
  }
}

.profile-title {
  display: flex;
  flex-direction: column;
}

.profile-contact {
  display: flex;
  flex-direction: column;
}

.profile-contact p {
  margin: 0.5em;
}

.App .content-profile h3 {
  color: #d1242a;
  margin-bottom: 0;
}

.App .content-profile h2 {
  margin: 0;
}

.App .content-profile h4 {
  margin-top: 0.5em;
  color: #d1242a;
}

.App .content-profile p {
  margin-left: 0;
  margin-bottom: 0;
}

.img-profile img {
  border-radius: 50%;
  width: 200px;
  object-fit: cover;
}

.photo-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.photo-slider figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.photo-slider figure img, figcaption {
  display: block;
  max-width: 60%;
  max-height: 10%;
  width: auto;
  height: auto;
}

@media only screen and (max-width: 1170px) {
  .photo-slider figure img, figcaption {
    max-width: 90%;
    max-height: 50%;
  }
}

.social-media-cv {
  text-align: center;
  display: flex;
  align-items: center;
}

.img_picture {
  cursor: zoom-in;
}